import request from '@/utils/request'
// 获取美团授权url
export function getAuthUrl(data) {
  return request('get', '/apm/meituan/oauth/getAuthUrl', data)
}
// 美团授权门店列表
export function getTableList(data) {
  return request('post', '/apm/meituan/oauth/list', data)
}

// 美团授权门店列表 - 不分页
export function listByMerchantId(data) {
  return request('post', '/apm/meituan/oauth/listByMerchantId', data)
}

// 添加美团商户授权
export function oauthToken(data) {
  return request('post', '/apm/meituan/oauth/token', data)
}

// 绑定门店
export function getTableEdit(data) {
  return request('post', '/apm/meituan/oauth/bind', data)
}